.books {
    padding: 3rem 1rem;

    > .wrapper {
        margin-bottom: 2rem;

        @media #{$mq-tablet-s} {
            > .columns {
                > .column {
                    max-width: 47%;
                }
            }
        }

        @media (min-width: 1216px) {
            > .columns {
                > .column {
                    max-width: 22%;
                }
            }
        }

        @media (min-width: 1400px) {
            > .columns {
                > .column {
                    max-width: 23%;
                }
            }
        }
    }

    .page-title {
        padding: 25px 0;
        color: $bprimary;
        font-size: 30px;
        font-weight: $fwb;
        text-align: center;
        // text-transform: uppercase;
        line-height: 2;
    }

    .book {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        margin: 1rem 0 0;
        padding: 1.5rem;
        border: 1px solid $gray01;
        -webkit-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
        box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.5);
        color: $gray05;
        height: 100%;
        max-height: 515px;

        .title {
            flex: 1 0 auto;
            color: $gray05;
            margin-bottom: 1rem;
            font-size: 22px;
        }

        > .content {
            flex: 2 1 auto;
            text-align: left;
            font-size: 16px;
            line-height: 1.2;
            margin-bottom: 2rem;
            position: relative;
            max-height: 35%;

            &:hover .tooltip,
            &:hover .tooltip:after {
                display:block;
            }

            .book-sample {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }

            > img {
                display: block;
                height: 100%;
                width: auto;
                margin: 0 auto;
            }

            .tooltip {
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                left:100%;
                margin-left:15px;
                z-index: 10;
                width: 300px;
                padding:10px;
                border-radius:10px;
                background:#000;
                color: #fff;
                text-align: left;
                white-space: wrap;
                hyphens: auto;

                display:none;
            }

            .tooltip:after {
                content: "";
                position:absolute;

                left: -15px;
                margin-left:-5px;

                top:50%;
                transform:translateY(-50%);

                border:10px solid #000;
                border-color: transparent black transparent transparent;

                display:none;
            }

            .tooltip.left {
                left:initial;
                margin:initial;

                right:100%;
                margin-right:15px;
            }

            .tooltip.left:after {
                left: auto;
                right: -15px;
                margin-left: 0;
                margin-right: -5px;
                border-color: transparent transparent transparent black;
            }
        }

        .details {
            display: block;
            font-size: 16px;
            height: 120px;

            .detail {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 10px;

                span {
                    display: inline-block;
                    height: 18px;

                    &:first-child {
                        width: 34%;
                    }
                    &:last-child {
                        width: 64%;
                        text-align: right;
                        display: inline-block;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    s {
                        font-size: 12px;
                    }
                }
            }
        }

        .btn {
            margin-top: 1rem;
            width: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
    }

    .content {
        text-align: center;
        color: $gray05;
    }

    .order-result {
        display: block;
        width: 100%;
        padding: 1.5rem;
        margin-bottom: 2rem;
        background-color: $bsuccess;
        color: $bwhite;
        text-align: center;
        font-size: 16px;

        p:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}

.book-popup-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($popup-bg, 0.7);
    @include transition(opacity, 300ms, ease-in-out);
    visibility: hidden;
    opacity: 0;

    &.is-active {
        visibility: visible;
        opacity: 1;
    }

    .popup-body {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 440px;
        padding: 3em 0;
        background: $gray01;
        border: 1px solid $gray035;
        box-shadow: 4px 0 63px 0 rgba($bblack, 0.47);
        border-radius: 3px;
        @include transition(all, 300ms, ease-in-out);

        .popup-link {
            font-size: 13px;
            color: $gray05;
            text-decoration: underline;
        }

        .title {
            color: $gray05;
            margin-bottom: 2rem;
            font-size: 22px;
        }

        &__close {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 10;
            cursor: pointer;
        }

        &__form {
            width: 98%;
            max-width: 300px;
        }

        .form {

            .book-logo {
                margin-bottom: 30px;
                opacity: .2;
            }

            .btn {
                align-items: center;
                justify-content: center;
            }

            .block--input {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                margin-bottom: 15px;

                label {
                    font-size: 14px;
                }

                // input {
                //     padding-left: 43px;
                // }

                // &::before {
                //     content: '';
                //     position: absolute;
                //     left: 10px;
                //     width: 23px;
                //     height: 100%;
                //     background-position: center;
                //     background-repeat: no-repeat;
                // }

                // &.email {
                //     &::before {
                //         background-image: url(/assets/frontend/img/at.png);
                //     }
                // }

                // &.password {
                //     &::before {
                //         background-image: url(/assets/frontend/img/password.png);
                //     }
                // }

                &.errors {
                    display: none;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 10px 0;
                    font-size: 14px;
                    font-weight: $fwr;
                    border: 1px solid $bdanger;
                    border-radius: 3px;
                    color: $bdanger;
                    background: lighten($bdanger, 35%);

                    &.error {
                        display: flex;
                    }
                }
            }

            &__links {
                padding: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

.book-form {
  max-width: 300px;
  margin: 0 auto;

  .form__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
  }
}
