@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i&subset=latin-ext');

// Font declarations
$fw-hairline:               100;
$fw-light:                  300;
$fw-regular:                400;
$fw-semibold:               500;
$fw-bold:                   700;
$fw-extrabold:              900;

$fwh:                       $fw-hairline;
$fwl:                       $fw-light;
$fwr:                       $fw-regular;
$fws:                       $fw-semibold;
$fwb:                       $fw-bold;
$fwe:                       $fw-extrabold;

// Base Font
$base-font-size:            16;
$base-font-sizepx:          16px;
$base-line-height:          1.25em;
$base-font-family:          'Open Sans', sans-serif;
$base-font-weight:          $fw-regular;
$bfaf:                      $base-font-family;

$icon-font-family:          'FontAwesome';
$ifaf:                      $icon-font-family;

// Secondary Font
// $secondary-font-size:       $base-font-size;
// $secondary-font-family:     'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
// $secondary-line-height:     $base-line-height;
// $sfaf:                      $secondary-font-family;

// Fixed Font
$fixed-font-size:           85%;
$fixed-font-family:         monospace;
$fixed-line-height:         $base-line-height;

// Font sizes
@function em($target, $context: $base-font-size) {
    @return ($target / $context) * 1em;
}

.article-single {

    h1 {
        font-size: 32px !important;
    }

    h2 {
        font-size: 28px !important;
    }

    h3 {
        font-size: 24px !important;
    }

    h4 {
        font-size: 22px !important;
    }

    h5 {
        font-size: 20px !important;
    }

    td {
        padding: 5px;
    }

}
