.form {
  .block {
    &--label {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 15px;

      &.inline {
        &--sm {
          max-width: 130px;
        }
      }

      &.error {
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="number"] {
          color: $bdanger;
          border-color: $bdanger;
        }

        &.checkbox {
          .custom-checkbox {
            border-color: $bdanger;
          }
        }
      }

      &.radio {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        input[type="radio"] {
          display: none;

          &:checked + .custom-radio {
            &:before {
              background-color: $bprimary;
            }
          }
        }

        .custom-radio {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          margin-right: 1em;
          background-color: $bwhite;
          border: 1px solid $gray04;
          border-radius: 100%;
          cursor: pointer;

          &:before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: $bwhite;
            border-radius: 100%;
            @include transition(all, 300ms, ease-in-out);
          }
        }

        .po-img {
          width: auto;
          max-height: 55px;
        }
      }

      &.checkbox {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        input[type="checkbox"] {
          display: none;

          &:checked + .custom-checkbox {
            &:before {
              background-color: $bprimary;
            }
          }
        }

        .custom-checkbox {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          margin-right: 1em;
          background-color: $bwhite;
          border: 1px solid $gray04;
          border-radius: 6px;
          cursor: pointer;

          &:before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: $bwhite;
            border-radius: 6px;
            @include transition(all, 300ms, ease-in-out);
          }
        }
      }

      .label-text {
        margin-bottom: 10px;
        color: $gray05;
        font-size: 18px;
        font-weight: $fwb;
        line-height: 1.2;
      }

      .error-text {
        margin-top: 10px;
        color: $bdanger;
        font-size: 14px;
        font-weight: $fwb;
        line-height: 1.2;
      }

      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="number"],
      select {
        width: 100%;
        height: 54px;
        color: $gray05;
        font-size: 14px;
        font-weight: $fwr;
        padding: 0 12px;
        outline: none;
        border-radius: 4px;
        border: 1px solid $gray03;
        background: $bwhite;
        -webkit-appearance: none;
        @include bb;
        @include transition(all, 300ms, ease-in-out);
        &:focus {
          border-color: $gray04;
        }

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
          color: $gray035;
        }
      }

      select {
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
        appearance: menulist;
      }
    }

    &--input {
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="number"],
      select {
        width: 100%;
        height: 54px;
        color: $gray05;
        font-size: 14px;
        font-weight: $fwr;
        padding: 0 12px;
        outline: none;
        border-radius: 4px;
        border: 1px solid $gray03;
        background: $bwhite;
        -webkit-appearance: none;
        @include bb;
        @include transition(all, 300ms, ease-in-out);
        &:focus {
          border-color: $gray04;
        }

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
          color: $gray035;
        }
      }

      select {
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
        appearance: menulist;
      }

      &.error {
        input[type="text"],
        input[type="email"],
        input[type="password"],
        select {
          color: $bdanger;
          border-color: $bdanger;
        }
      }
    }

    &--error {
      input[type="text"],
      input[type="email"],
      input[type="number"],
      select {
        color: $bdanger;
        border-color: $bdanger;
      }
    }
  }
}

@media #{$mq-tablet-s} {
  .form {
    .block {
      &--label {
        &.inline {
          width: 49%;
        }
      }
    }
  }
}
