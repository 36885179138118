.icon {
    display: inline-block;
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    $icons: arrow-down
            arrow-up
            arrow-right
            flag-bgr
            flag-hun
            flag-rou
            icon-chat
            icon-mail
            icon-mail-gray
            icon-close
            icon-phone
            info-dot
            view;

    @each $icon_name in $icons {
        &--#{$icon_name} { background-image: url(/img/icons/#{$icon_name}.svg); }
    }

    &--arrow { width: 17px; height: 17px; }
    &--flag { width: 21px; height: 15px; }
    &--icon { width: 36px; height: 36px; }
    &--info { width: 15px; height: 15px; }
}
