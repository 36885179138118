@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
.hero {
    position: relative;

    &__bg {
      position: relative;
      display: block;
      width: 100vw;
      height: 100vw;
      background-size: cover;
      background-position: center center;
      background-image: url(/assets/frontend/img/student.png);
    }

    .controls {
      position: absolute;
      z-index: 100;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2em;

      &--play {
        animation-duration: .9s;
        animation-name: pulse;
        animation-iteration-count: infinite;
      }
    }

    &__content {
        position: relative;
        z-index: 1;
        @include flex(flex-end, center);
        flex-direction: column;
        height: 25em;
        margin-top: -100px;
        padding: 20px;
        overflow: hidden;

        &::before {
            position: absolute;
            top: 0; left: -50vw; z-index: -1;
            display: block;
            content: '';
            @include circle(200vw, $bwhite);
        }

        p {
            color: $gray05;
            font-size: 21px;
            line-height: 1.5em;
            text-align: center;
        }

        .btn { margin-top: 20px; }
    }

    .wrapper { width: 100%; }
}

@media #{$mq-tablet-s} {
    .hero {
        &__bg { height: calc(100vw / 2); }

        &__content {
            position: relative;
            top: auto; left: auto;
            justify-content: center;
            width: 370px;
            height: 370px;
            overflow: visible;
            padding: 0 50px;
            margin-top: 0;
            @include bb;

            &::before {
                left: auto;
                @include circle(370px, rgba($bwhite, .95));
            }

            p { font-size: 18px; }
        }

        .wrapper {
            position: absolute;
            top: 0; left: 6vw;
            @include flex(flex-start, center);
            width: 88vw;
            height: 100%;
        }
    }
}
