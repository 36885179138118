.site-header {
  position: relative;
  z-index: 2;
  background-color: $bwhite;

  &__highbar {
    .wrapper {
      height: 160px;
    }
  }

  .highbar {
    &__logo {
      display: block;
      width: 277px;
      height: 69px;
      margin: 15px auto;
      background-image: url(/assets/frontend/img/testmaximizer-logo.svg);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;

      span {
        visibility: hidden;
      }
    }

    &__controls {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
    }

    .controls {
      .btn {
        margin-left: 0.5em;

        &:first-of-type {
          margin-left: 0;
        }
      }

      .btn-logout {
        display: none;
        padding: 0 5px;
      }

      .btn-profile {
        span {
          display: none;
        }
      }

      .btn--icon {
        span {
          margin-left: 0.5em;
        }
      }
    }
  }

  .lowbar {
    box-shadow: 0 2px 7px 0 rgba($bblack, 0.27);

    &__navigation {
    }

    .wrapper {
      width: 100%;
    }
  }

  .navigation {
    &__toggler {
      display: block;
      height: 60px;
      color: $gray05;
      background-color: $bwhite;
      font-size: 20px;
      line-height: 60px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;

      i {
        margin-right: 0.5em;
      }
    }

    &__list {
      display: none;
      background-color: $gray01;
      text-transform: uppercase;

      .submenu {
        display: block;
        text-align: center;
        padding: 0.8em 0;
        margin: 0.2em 0;

        &[data-submenu="open"] {
          background-color: $bwhite;
          box-shadow: 0 0 3px rgba($bblack, 0.27);
        }
      }

      .nav-item {
        display: block;
        text-align: center;
        padding: 0.8em 0;
        margin: 0.2em 0;

        &.has-text-red a {
          color: $bdanger;
        }

        a {
          color: $gray05;
        }
      }
    }

    .list {
      &__item {
        display: block;
        padding: 0.6em 0;
        color: $gray05;
        font-size: 16px;
        line-height: 1.4;
        text-align: center;
      }

      .item {
        a {
          color: $gray05;
        }

        &__sub-sub-title {
          font-weight: 400;

          &::after {
            content: $fa-var-angle-right;
            font-family: $ifaf;
            margin-left: 0.5em;
          }
        }

        &__sub-sub-list {
          width: 50%;
          max-width: 200px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          border-left: 1px solid $gray01;
        }

        &__sub-sub-item {
          justify-content: flex-start;
          padding: 0.5em 0;
          margin: 0.2em 0 0.2em 5px;
          font-size: 15px;
        }
      }
    }

    .submenu {
      .submenu__toggler {
        cursor: pointer;

        &::after {
          content: $fa-var-angle-down;
          font-family: $ifaf;
          margin-left: 0.5em;
        }
      }

      &__desktop-toggler {
        display: none;
        cursor: pointer;
      }

      &__list {
        display: none;

        .list {
          a,
          span {
            display: block;
            color: $gray05;
            font-size: 15px;
            line-height: 1.2;
            text-align: center;
            text-transform: uppercase;
          }

          &__item {
            li {
              width: auto;
              display: flex;
              align-items: center;
              justify-content: center;

              a {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .submenu[data-submenu="open"] {
      .submenu__toggler {
        &::after {
          content: $fa-var-angle-up;
        }
      }
    }

    [data-menu="open"],
    [data-submenu="open"] .submenu__list {
      display: block;
      margin-top: 0.5em;
    }
  }
}

.site-content {
  flex: 1 0 0;
}

.site-footer {
  background-color: $bwhite;
  box-shadow: 0 2px 7px 0 rgba($bblack, 0.27);

  .wrapper {
    width: 100%;
  }

  &__privacy {
    margin-top: 1.5rem !important;

    a {
      display: block;
      color: white;
      font-size: 14px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 88vw;
    padding: 20px 0;
    margin: 0 auto;
    color: $gray05;
    list-style: none;
    font-size: 12px;
    text-transform: uppercase;
  }

  .menu {
    &__item {
      padding: 10px 15px;

      a {
        color: $gray05;

        &:visited {
          color: $gray05;
        }

        &:hover {
          color: $bprimary;
          @include transition(all, 250ms, color);
        }
      }
    }
  }

  &__connect {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 50px 0;
    margin: 0 auto;
    color: $bwhite;
    background-image: url(/assets/frontend/img/bg.png);
    background-position: top center;
  }

  .connect {
    &__title {
      font-size: 30px;
      font-weight: $fwb;
      line-height: 3;
      text-align: center;
      text-transform: uppercase;
    }

    hr {
      width: 92px;
      border-top: 1px solid #ffffff;
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 265px;
      padding-top: 35px;
    }

    .list {
      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $bwhite;
      }
    }
  }
}

@media #{$mq-tablet-s} {
  .site-header {
    &__highbar {
      .wrapper {
        @include flex(space-between, center);
        height: 100px;
      }
    }

    .highbar {
      &__logo {
        margin: 15px 0;
      }

      &__controls {
        margin: 0 0 0 50px;
      }
    }

    .lowbar {
      &__navigation {
        width: 100%;
      }
      .wrapper {
        @include flex(flex-start, center);
        width: 100%;
      }
    }
  }

  .site-footer {
    .connect {
      padding: 100px 0;

      &__title {
        font-size: 35px;
      }

      &__list {
        width: 450px;
      }

      .list {
        &__item {
          .fa {
            font-size: 2.5em;
          }
        }
      }
    }
  }
}

@media #{$mq-tablet} {
  .site-header {
    .highbar {
      .controls {
        .btn-profile {
          span {
            display: inline;
          }
        }

        .btn-logout {
          display: inline-flex;

          span {
            display: none;
          }
        }
      }
    }

    .lowbar {
      .wrapper {
        width: 97vw;
      }
    }

    .navigation {
      &__toggler {
        display: none;
      }

      &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
        width: 100%;
        background-color: $bwhite;

        .submenu {
          display: inline-block;
          height: 60px;
          padding: 0 1em;
          margin: 0;
          white-space: nowrap;
        }

        .nav-item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px;
          padding: 0 1em;
          margin: 0;
          white-space: nowrap;
        }
      }

      .list {
        .item {
          &__sub-sub-list {
            width: calc(100% - 5px);
            margin-left: 5px;
          }

          &__sub-sub-item {
            justify-content: flex-start !important;
            text-align: left;
          }
        }
      }

      .submenu__toggler {
        display: none;
      }

      .submenu {
        position: relative;

        .submenu__list {
          z-index: 2;
          flex-direction: column;
          align-items: center;
          position: absolute;
          min-width: 160px;
          padding: 0;
          left: 0;
          background-color: $bwhite;
          box-shadow: 0px 15px 30px 5px rgba($bblack, 0.4);

          .list {
            &__item {
              width: calc(100% - 2em);
              padding: 0.6em 1em;
              margin: 0;
              font-size: 15px;
              text-align: left;
              border-bottom: 1px solid $gray01;

              &:hover {
                border-bottom: 1px solid $gray05;
              }
            }
          }
        }

        &:hover {
          box-shadow: 0px 15px 30px 5px rgba($bblack, 0.4);

          .submenu__list {
            display: flex;
          }
        }

        &__desktop-toggler {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px;
        }
      }
    }
  }

  .blog-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__items {
      width: 49%;
    }

    &__title {
      min-height: 100px;
    }
  }
}

@media #{$mq-desktop-l} {
  .site-header {
    .lowbar .wrapper {
      width: $wrapper-desktop;
    }
  }
}
