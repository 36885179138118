.margin-top {

  &--xl {
    margin-top: 80px;
  }
}

.margin-bottom {
  &--lg {
    margin-bottom: 2rem;
  }
}

.has-text-centered {
  text-align: center;
}

.has-text-left {
  text-align: left;
}

.has-text-right {
  text-align: right;
}