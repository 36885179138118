/////////////////////////////////
// Mixins and Functions        //
/////////////////////////////////
@mixin circle($width: 5em, $color: $bwhite) {
    width: $width;
    height: $width;
    background: $color;
    -webkit-border-radius: $width/2;
       -moz-border-radius: $width/2;
            border-radius: $width/2;
}

@mixin circle-pc($width: 5em, $color: $bwhite) {
    position: relative;
    width: $width;
    height: 0;
    padding: $width/2 0;
    background: $color;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        width: 100%;
        content: '';
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
    }
}

@mixin hover($color) {
    &:hover { background-color: shade($color, 10%); }
}

@mixin title($font-size: 20, $color: $gdarkest, $font-weight: $fwb, $line-height: 1.3em, $margin-bottom: .5em) {
    margin-bottom: $margin-bottom;
    color: $color;
    font-size: em($font-size);
    font-weight: $font-weight;
    line-height: $line-height;
}

@mixin wf($w: 100%, $f: left) {
    width: $w;
    float: $f;
}

@mixin lund($color: $bblack) {
    a { color: $color; &:hover { text-decoration: underline; } }
}

@mixin lcol($color: $bblack, $percent: 15%) {
    a {
        color: $color;
        &:hover { color: shade($color, $percent); }
        @include transition(color, 0.15s, ease-in-out);
    }
}

@mixin lcoldiff($color: $bblack, $colorhover: $bblack, $percent: 15%) {
    a {
        color: $color;
        &:hover { color: $colorhover; }
        @include transition(color, 0.15s, ease-in-out);
    }
}

@mixin vp($name, $argument) {
    -webkit-#{$name}: #{$argument};
    -moz-#{$name}: #{$argument};
    #{$name}: #{$argument};
}

@mixin bb {
    @include vp(box-sizing, border-box);
}

@mixin column-count($columns) {
    @include vp(column-count, $columns);
}

@mixin rotate($degrees) {
    -webkit-transform: rotate(#{$degrees}deg);
       -moz-transform: rotate(#{$degrees}deg);
        -ms-transform: rotate(#{$degrees}deg);
         -o-transform: rotate(#{$degrees}deg);
            transform: rotate(#{$degrees}deg);

    filter:  progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)});
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
    zoom: 1;
}

@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
       -moz-transition: $transition-property $transition-time $method;
        -ms-transition: $transition-property $transition-time $method;
         -o-transition: $transition-property $transition-time $method;
            transition: $transition-property $transition-time $method;
}

@mixin triangle($direction: "down", $size: 20px, $color: #000) {
    width: 0;
    height: 0;
    border-bottom: $size solid #{set-triangle-color($direction, "bottom", $color)};
     border-right: $size solid #{set-triangle-color($direction, "right", $color)};
      border-left: $size solid #{set-triangle-color($direction, "left", $color)};
       border-top: $size solid #{set-triangle-color($direction, "top", $color)};
}

@mixin flex($justify: flex-start, $align: flex-start, $flex: flex) {
    display: $flex;
    justify-content: $justify;
    align-items: $align;
}

// Right-Angled Triangle
@mixin triangle-ra($width, $height, $direction, $color) {
    width: 0;
    height: 0;
    content: '';
    border-style: solid;

    @if $direction == bottom-left{
        border-color: transparent transparent transparent $color;
        border-width: $height 0 0 $width;
    }

    @if $direction == bottom-right {
        border-color: transparent transparent $color transparent;
        border-width: 0 0 $height $width;
    }

    @if $direction == top-left {
        border-color: $color transparent transparent transparent;
        border-width: $height $width 0 0;
    }

    @if $direction == top-right {
        border-color: transparent $color transparent transparent;
        border-width: 0px $width $height 0px;
    }
}

@function set-triangle-color($direction, $side, $color) {
    @if $direction == "left" and $side == "right"
     or $direction == "right" and $side == "left"
     or $direction == "down" and $side == "top"
     or $direction == "up" and $side == "bottom" {
        @return $color
    } @else {
        @return "transparent";
    }
}

/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
    $convertable-units: deg grad turn rad;
    $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
    @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
        @return $value
                         / nth($conversion-factors, index($convertable-units, unit($value)))
                         * nth($conversion-factors, index($convertable-units, $unit));
    }

    @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
    $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
    $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

    @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
    @if is-direction($value) == false {
        @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
    }

    $conversion-map: (
        to top          : bottom,
        to top right    : bottom left,
        to right top    : left bottom,
        to right        : left,
        to bottom right : top left,
        to right bottom : left top,
        to bottom       : top,
        to bottom left  : top right,
        to left bottom  : right top,
        to left         : right,
        to left top     : right bottom,
        to top left     : bottom right
    );

    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }

    @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction) == false {
        $color-stops: ($direction, $color-stops);
        $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}
