html { height: 100%; }

body {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    color: $bblack;
    font-size: $base-font-sizepx;
    font-family: $base-font-family;
    text-align: center;
    overflow-y: scroll;
    background: $body-background-color;
}

.wrapper {
    width: 88vw;
    margin: 0 auto;
    text-align: left;
    @include bb;
}

@include lcol($bprimary);

@media #{$mq-desktop-l} {
    .wrapper { width: $wrapper-desktop; }
}
