// Buttons
.btn {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    color: $bblack;
    font-size: 14px;
    font-weight: $fwr;
    font-family: $bfaf;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    border-radius: $bdr;
    @include bb;
    @include transition(color, 0.15s, ease-in-out);
    @include transition(border-color, 0.15s, ease-in-out);
    @include transition(background, 0.15s, ease-in-out);
    @include transition(background-color, 0.15s, ease-in-out);

    &--bold { font-weight: $fwb; }
    &--fullwidth { width: 100%; }
    &--uppercase { text-transform: uppercase; }
    &--login {
        justify-content: center;
        height: 64px;
        font-size: 25px;
    }

    &--primary {
        color: $bwhite;
        background-color: $bprimary;
        border: 1px solid $bprimary;

        &:hover { background-color: darken($bprimary, 5%); }
    }

    &--secondary {
        color: $bwhite;
        background-color: $bsecondary;
        border: 1px solid $bsecondary;

        &:hover { background-color: darken($bsecondary, 5%); }

        &--empty {
            color: $bsecondary;
            background-color: $bwhite;
            border: 1px solid $bsecondary;

            &:hover {
                color: darken($bsecondary, 5%);
                border: 1px solid darken($bsecondary, 5%);
            }
        }
    }

    &--order {
        justify-content: center;
        width: 240px;
        height: 70px;
        font-size: 16px;
        color: $bwhite;
        background-color: $bsecondary;
        border: 1px solid $bsecondary;

        &:hover { background-color: darken($bsecondary, 5%); }

        &:disabled {
            color: $gray035;
            background-color: $bwhite;
            border: 1px solid $bwhite;
            cursor: not-allowed;

            &:hover { background-color: darken($bwhite, 5%); }
        }
    }

    &--gray {
        color: $bprimary;
        background-color: $gray01;
        border: 1px solid $gray01;

        &:hover { background-color: darken($gray01, 5%); }
    }

    &--white {
        justify-content: center;
        width: 200px;
        height: 70px;
        color: $bsecondary;
        background-color: $bwhite;
        border: 1px solid $bwhite;

        &:hover { background-color: $gray01; }
    }
}

.back-link__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 35px;
    color: $gray035;
    background-color: $bwhite;
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 3px;
    @include transition(color, 0.15s, ease-in-out);

    i {
        margin-right: 1em;
    }

    &:hover {
        color: $gray05;
    }
}
