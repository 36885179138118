// Brand Colors
$bprimary:                  #076c90 !default;
$bsecondary:                #f54b5e !default;
$popup-bg:                  #1A3B47 !default;

$bwhite:                    #ffffff !default;
$gray00:                    #F9F9F9 !default;
$gray01:                    #eeeeee !default; // gray btn
$gray02:                    #acacac !default;
$gray03:                    #bdbdbd !default;
$gray035:                   #9f9f9f !default;
$gray04:                    #595959 !default;
$gray05:                    #505050 !default; // text base
$gray06:                    #3b3b3b !default;
$gray07:                    #333333 !default;
$gray08:                    #242424 !default;
$gray09:                    #1c1c1c !default;
$bblack:                    #000000 !default;

$bsuccess:                  #48d2a0 !default;
$bwarning:                  #e48113 !default;
$bdanger:                   #ed4d5d !default;
$status-colors:             $bdanger, $bsuccess, $bwarning;

$body-background-color:     $bwhite;

$wrapper-desktop:           1230px;

$border-radius:             4px;
$bdr:                       $border-radius;

// Font sizes
$fs-xxs:                    10 !default;
$fs-xs:                     12 !default;
$fs-s:                      14 !default;
$fs-m:                      16 !default;
$fs-l:                      24 !default;
$fs-xl:                     36 !default;
$fs-xxl:                    52 !default;

// Pagebody colors
$stressed-text:             $bsecondary;
$hr-border:                 $gray04;
$tc-base:                   $gray09;
$tc-link:                   #3da4c3;
$figcaption-bg:             $gray03;

// Media query breakpoints
$mq-desktop-l: 'screen and (min-width: 1440px)';
$mq-desktop:   'screen and (min-width: 1280px)';
$mq-tablet:    'screen and (min-width: 1024px)';
$mq-tablet-s:  'screen and (min-width: 768px)';
$mq-mobile-l:  'screen and (min-width: 375px)';
