.placement-test {
  padding-bottom: 25px;

  &__title {
    margin: 25px 0;
    color: $bprimary;
    font-size: 30px;
    font-weight: $fwb;
    line-height: 1.25em;
    text-align: center;
    text-transform: uppercase;
  }

  &__btn {
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 10px;
    font-size: 19px;
    line-height: 1.25em;
  }
}

.main-articles {
  background-color: rgba(#505050, 0.1);
  position: relative;
  box-shadow: 0px 10px 55px -10px rgba($bblack, 0.25);

  &__title {
    padding: 25px 0;
    color: $bprimary;
    font-size: 30px;
    font-weight: $fwb;
    line-height: 1.25em;
    text-align: center;
    text-transform: uppercase;
  }

  &__item {
    padding-bottom: 20px;
    background-color: $bwhite;
    border-radius: 3px;
  }

  .wrapper {
    width: 100vw;
  }

  .item {
    &__image {
      @include flex(center, center);
      max-height: 100vw;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
      }
    }

    &__content {
      width: 88vw;
      margin: 0 auto;
    }
  }

  .content {
    &__title {
      margin: 1em 0;
      color: $bprimary;
      font-size: 20px;
      font-weight: $fwr;
      line-height: 1.25em;
      text-transform: uppercase;
    }

    &__body {
      margin: 1em 0;
      color: $gray05;
      font-size: 14px;
      line-height: 1.5em;
    }
  }
}

.daily-word {
  background-color: $gray00;

  .wrapper {
    width: 100%;
  }

  &__title {
    padding: 50px 0 30px 0;
    color: $bprimary;
    font-size: 30px;
    font-weight: $fwb;
    line-height: 1.25em;
    text-align: center;
    text-transform: uppercase;
  }

  &__box {
    padding: 2em;
    color: $gray05;
    background-color: $bwhite;
    box-shadow: 0 0 20px 0 rgba($bblack, 0.25);
    border-radius: 3px;
  }

  .box {
    &__title {
      display: block;
      font-size: 30px;
      font-weight: $fwb;
      line-height: 1.25em;
      text-transform: lowercase;
    }

    hr {
      margin-left: -1em;
      margin-right: -2em;
    }

    &__pronounce {
      display: block;
      padding-bottom: 25px;
      font-size: 12px;
      text-transform: uppercase;
    }

    &__description {
      font-size: 14px;
      line-height: 1.8;

      ul {
        padding-left: 40px;
      }
    }
  }

  &__social {
    color: $gray05;
    padding: 50px 1.2em 30px 1.2em;
  }

  .social {
    &__text {
      text-align: center;
      font-size: 15px;
      line-height: 1.2;
    }

    &__icons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0 20px 0;

      .icon {
        &:first-of-type {
          margin-right: 30px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.exams {
  padding-top: 40px;
  color: $gray05;

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .list {
    &__item {
      width: 255px;
      padding-bottom: 40px;
    }

    .item {
      &__title {
        padding-bottom: 15px;
        font-size: 20px;
        line-height: 1.4;
        color: $bprimary;
      }

      &__line {
        font-size: 14px;
        line-height: 1.8;

        a {
          color: $bsecondary;
          font-weight: $fwb;
        }
      }
    }
  }
}

.categories {
  background-color: $gray00;
  padding: 35px 0 100px 0;

  &__title {
    color: $bprimary;
    text-align: center;
    text-transform: uppercase;
    font-size: 45px;
    line-height: 1.1;
    margin: 15px 0;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .list {
    &__item {
      width: 100%;
      max-width: 900px;
      margin-bottom: 20px;
      background-color: $bwhite;
      border-radius: 3px;
      box-shadow: 0 0 21px 0 rgba($bblack, 0.25);
    }

    .item {
      &__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 110px;
        padding: 10px 30px;
        color: $bprimary;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-size: 23px;
        line-height: 1.5;
        text-align: center;
        text-transform: uppercase;
      }

      &__date {
        padding: 0 30px;
        color: $bprimary;
        font-size: 13px;
      }

      &__content {
        padding: 25px 30px;
        color: $gray05;
        font-size: 14px;
        line-height: 1.5;
      }

      .content {
        &__text {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.article-list {
  background-color: $gray00;
  padding: 35px 0 100px 0;

  .wrapper {
    width: 95vw;
  }

  &__title {
    color: $bprimary;
    text-align: center;
    text-transform: uppercase;
    font-size: 45px;
    line-height: 2;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list {
    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;
      background-color: $bwhite;
      border-radius: 3px;
      box-shadow: 0 0 21px 0 rgba($bblack, 0.25);
    }

    .item {
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 110px;
        color: $bprimary;
        background-color: $gray00;
        border-radius: 3px 3px 0 0;
        font-size: 20px;
        line-height: 1.3;
        text-transform: uppercase;
        text-align: center;
      }

      &__text {
        padding: 20px 40px;
        color: $gray05;
        font-size: 14px;
        line-height: 1.6;
      }

      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
      }
    }
  }
}

.article-single {
  background-color: $gray00;
  padding: 35px 0 100px 0;

  &__title {
    margin: 20px 0;
    color: $bprimary;
    text-align: center;
    font-size: 30px;
    line-height: 1.2;
  }

  &__date {
    margin: 10px 0;
    color: $bprimary;
    font-size: 14px;
  }

  &__content {
    color: $gray05;
    font-size: 20px;
    line-height: 1.8;
  }

  .content {
    .disqus-thread {
      margin-top: 2em;
    }
  }

  &__locked-cto {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    font-size: 15px;
    border: 1px solid $gray03;
    box-shadow: 0 0 24px 0 rgba($bblack, 0.1);
  }

  .locked-cto {
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      padding: 0 25px;
      font-size: 20px;
      line-height: 1.8;
      border-bottom: 1px solid $gray03;
    }

    .content {
      img {
        margin-right: 1em;
      }

      &__text {
        color: $gray05;
        font-size: 16px;
      }
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      box-shadow: 0 0 24px 0 rgba($bblack, 0.1);
    }
  }
}

.packages {
  background-color: $gray00;
  padding: 35px 0 100px 0;

  .wrapper {
    width: 95vw;
  }

  &__title {
    margin: 40px 0;
    color: $bprimary;
    text-align: center;
    font-size: 30px;
    line-height: 1.2;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .list {
    &__item {
      width: 100%;
      box-shadow: 0 0 21px 0 rgba($bblack, 0.25);
      border-radius: 3px;
      background-color: $bwhite;
      margin-bottom: 2em;
    }

    .item {
      &__name {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90px;
        color: $bwhite;
        font-size: 24px;
        border-radius: 3px 3px 0 0;
        background-image: -webkit-linear-gradient(
          top left,
          #11a5c3 0%,
          #076c90 100%
        );
        background-image: -o-linear-gradient(
          top left,
          #11a5c3 0%,
          #076c90 100%
        );
        background-image: linear-gradient(
          to bottom right,
          #11a5c3 0%,
          #076c90 100%
        );
      }

      &__price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1em 0;
      }

      .price {
        &__old {
          color: $gray03;
          font-size: 23px;
          text-decoration: line-through;
          line-height: 1.4;
        }

        &__current {
          color: $bprimary;
          font-size: 40px;
          font-weight: $fwb;
          line-height: 1.1;
        }

        &__promo-text {
          color: $gray03;
          font-size: 15px;
          line-height: 2;
        }
      }

      &__features {
        padding: 2em;
        color: $gray05;
        background-color: $gray00;
        font-size: 15px;
        line-height: 1.8;

        ul {
          list-style-type: none;

          li {
            padding-left: 30px;
            padding-bottom: 1em;

            &:last-of-type {
              padding-bottom: 0;
            }

            &:before {
              content: $fa-var-check;
              font-family: $ifaf;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              margin-left: -30px;
              margin-right: 10px;
              border-radius: 50%;
              color: $bwhite;
              background-color: $bsuccess;
            }
          }
        }
      }

      &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em 0;
      }

      &.featured {
        .item__name {
          background-image: -webkit-linear-gradient(
            top left,
            #ff9164 0%,
            #f54b5e 100%
          );
          background-image: -o-linear-gradient(
            top left,
            #ff9164 0%,
            #f54b5e 100%
          );
          background-image: linear-gradient(
            to bottom right,
            #ff9164 0%,
            #f54b5e 100%
          );
        }

        .price {
          &__current {
            color: $bsecondary;
          }
        }
      }
    }
  }
}

.signup {
  background-color: $gray00;
  padding: 35px 0 100px 0;

  .wrapper {
    width: 95vw;
  }

  .back-link {
    &.profile {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-logout {
        i {
          display: none;
        }
      }
    }
  }

  &__title {
    margin: 15px 0;
    color: $bprimary;
    text-align: center;
    font-size: 30px;
    line-height: 1.2;
  }

  &__desc {
    margin-bottom: 35px;
    color: $gray05;
    text-align: center;
    font-size: 20px;
    line-height: 1.8;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form {
    &__group {
      flex-direction: column;
      width: 100%;
      max-width: 760px;
      margin-bottom: 30px;
      background-color: $bwhite;
      box-shadow: 0 0 21px 0 rgba($bblack, 0.25);
      border-radius: 3px;
    }

    .group {
      &__company-details {
        width: 100%;
        display: none;

        &.active {
          display: block;
        }
      }

      &__personal-details {
        display: block;
        width: 100%;

        &.inactive {
          display: none;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        color: $gray03;
        background-color: $gray00;
        font-size: 14px;
        text-transform: uppercase;
        border-radius: 3px 3px 0 0;
      }

      &__body {
        padding: 2em 1em;
      }

      .body {
        &--inline {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
        }

        .active-package {
          display: flex;
          flex-direction: row;
          width: 100%;
          max-width: 400px;
          margin: 30px auto;

          &__name {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            width: 40%;
            color: $bwhite;
            font-size: 20px;
            text-align: center;
            text-transform: uppercase;
            background-image: linear-gradient(135deg, #11a5c3 0%, #076c90 100%);
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &__expiration {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 25px 0;
            width: 60%;
            color: $gray05;
            background-color: $gray00;
            font-size: 15px;
          }

          .expiration {
            &__date {
              padding: 15px 10px 0 10px;
              font-weight: $fwb;
              font-size: 17px;
              text-align: center;
              line-height: 1.6;
            }
          }
        }

        .button-wrapper-centered {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
}

.thanks {
  background-color: $gray00;
  padding: 35px 0 100px 0;

  .wrapper {
    width: 95vw;
  }

  &__content {
    margin-top: 30px;
    color: $bwhite;
    background-color: $bsuccess;
    box-shadow: 0 0 21px 0 rgba($bblack, 0.25);
    border-radius: 3px;
  }

  .content {
    &.error {
      background-color: $bwhite;
      color: $bdanger;

      .header {
        color: $bwhite;
        background-color: $bdanger;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      background-color: darken($bsuccess, 10%);
      font-size: 14px;
      text-transform: uppercase;
      border-radius: 3px 3px 0 0;
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2em 1em;
    }

    .body {
      &__title {
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: $fwb;
        text-align: center;
        line-height: 1.3;
      }

      &__email {
        margin-bottom: 30px;
        font-size: 20px;
        text-align: center;
        line-height: 1.5;
      }

      &__welcome {
        margin-bottom: 30px;
        color: $bprimary;
        font-size: 25px;
        text-align: center;
        line-height: 1.2;
      }

      &__transaction {
        margin: 30px 0;
        color: $bprimary;
        font-size: 20px;
        text-align: center;
        line-height: 1.2;
      }
    }
  }
}

@media #{$mq-tablet-s} {
  .placement-test {
    &__title {
      font-size: 35px;
    }
    &__btn {
      width: auto;
      margin: 0 auto;
    }

    .wrapper {
      text-align: center;
    }
  }

  .main-articles {
    &__title {
      flex: 0 0 100%;
    }

    &__item {
      @include flex;
      flex-flow: row wrap;
      max-width: 585px;
      margin: 0 auto 30px auto;
      padding-bottom: 0;
      box-shadow: 0 0 20px 0 rgba($bblack, 0.25);
    }

    .wrapper {
      @include flex(center);
      flex-flow: row wrap;
      width: 88vw;
      padding-bottom: 30px;
    }

    .item {
      &__image {
        flex: 0 0 220px;
      }

      &__content {
        flex: 1 0 0;
        width: auto;
        padding: 0 40px;
      }
    }
  }

  .daily-word {
    &__title {
      font-size: 35px;
    }

    &__box {
      width: 80vw;
      max-width: 920px;
      padding: 2em 3em;
      margin: 0 auto;
    }

    .box {
      hr {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .exams {
    &__list {
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }

  .categories {
    &__title {
      margin-bottom: 30px;
    }
  }

  .article-list {
    &__title {
      margin-bottom: 30px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .list {
      &__item {
        width: 310px;
      }
    }
  }

  .article-single {
    .locked-cto {
      flex-direction: row;

      &__content {
        width: 62%;
        border-bottom: none;
        border-right: 1px solid $gray03;
      }

      .btn-wrapper {
        width: 38%;
      }
    }
  }

  .packages {
    &__list {
      flex-direction: row;
      justify-content: space-between;
    }

    .list {
      &__item {
        width: 32%;
      }

      .item {
        &__features {
          padding: 2em 1em;
        }
      }
    }
  }

  .signup {
    &__title {
      font-size: 45px;
    }

    &__desc {
      font-size: 26px;
    }

    .form {
      .group {
        &__body {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 2em 3em;
        }
      }

      .block {
        &--label {
          &.inline {
            &--sm {
              margin-left: 2%;
              margin-right: auto;
            }
          }

          &.radio {
            width: 50%;
            justify-content: center;
          }
        }
      }
    }
  }

  .thanks {
    &__content {
      max-width: 760px;
      margin-left: auto;
      margin-right: auto;
    }

    .content {
      &__body {
        padding: 5em;
      }

      .body {
        &__title {
          font-size: 70px;
        }
      }
    }
  }
}

@media #{$mq-tablet} {
  .categories {
    .wrapper {
      max-width: $wrapper-desktop;
    }
  }

  .article-single {
    &__title,
    &__date,
    &__content,
    &__locked-cto {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .article-list {
    .wrapper {
      max-width: $wrapper-desktop;
    }
  }

  .packages {
    .wrapper {
      max-width: $wrapper-desktop;
    }
  }

  .signup {
    .wrapper {
      max-width: $wrapper-desktop;
    }

    .back-link {
      &.profile {
        .btn-logout {
          display: none;
        }
      }
    }
  }

  .thanks {
    .wrapper {
      max-width: $wrapper-desktop;
    }
  }
}

@media #{$mq-desktop-l} {
  .packages {
    &__title {
      font-size: 45px;
    }

    .list {
      .item {
        &__features {
          padding: 2em;
        }
      }
    }
  }
}
