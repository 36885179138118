.login-popup-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($popup-bg, 0.7);
    @include transition(opacity, 300ms, ease-in-out);
    visibility: hidden;
    opacity: 0;

    &:target {
        visibility: visible;
        opacity: 1;
    }

    .popup-body {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 440px;
        padding: 3em 0;
        background: $gray01;
        border: 1px solid $gray035;
        box-shadow: 4px 0 63px 0 rgba($bblack, 0.47);
        border-radius: 3px;
        @include transition(all, 300ms, ease-in-out);

        .popup-link {
            font-size: 13px;
            color: $gray05;
            text-decoration: underline;
        }

        &__close {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        &__form {
            width: 98%;
            max-width: 300px;
        }

        .form {

            .login-logo {
                margin-bottom: 30px;
                opacity: .2;
            }

            .block--input {
                position: relative;
                margin-bottom: 15px;

                input {
                    padding-left: 43px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    width: 23px;
                    height: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.email {
                    &::before {
                        background-image: url(/assets/frontend/img/at.png);
                    }
                }

                &.password {
                    &::before {
                        background-image: url(/assets/frontend/img/password.png);
                    }
                }

                &.errors {
                    display: none;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 10px 0;
                    font-size: 14px;
                    font-weight: $fwr;
                    border: 1px solid $bdanger;
                    border-radius: 3px;
                    color: $bdanger;
                    background: lighten($bdanger, 35%);

                    &.error {
                        display: flex;
                    }
                }
            }

            &__links {
                padding: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

.login-form {
  max-width: 300px;
  margin: 0 auto;

  .form__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
  }
}
